<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	input:{},
            	heroImage:{},
            	data:[],
            	info:false,
				infoText:'',
            }
        },
        async mounted() {
        	await this.ready();
        		Vue.component("Header",()=>import("@frontend/part/Header.vue"))
          	this.$set(this.$root, 'page', this);
          	if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
          	this.apiGetSalses()
           	this.apiGetHero()
        },
        methods: {
        	apiGetSalses(){
        		Gen.apirest('/sales', {}, (err, resp)=>{
	              	if(err) console.log(err)
	              	this.data=resp.data
	          	})
        	},
        	apiGetHero(){
	            Gen.apirest('/hero/4', {}, (err, resp)=>{
	                if(err) console.log(err)
	                this.heroImage=resp.data
	            	this.$root.topProgress.done()
	            })
          	},
        	submitForm(e){
        		if(e.btnLoading()) return;
        		Gen.apirest("/contact", this.input, (err,resp)=>{
        			e.btnUnloading();
			        this.info=true
			        this.infoText=resp.message
			        setTimeout(()=>{
			          	this.$router.push({name:"index"});
			        },3000)
			    }, "POST");
        	}
        }
    };
</script>
<template>
  	<div Content>
	  	<Header></Header>
	    <section id="slider" class="slider-element slider-parallax swiper_wrapper sliderActivities clearfix">
			<!-- slider-parallax-inner -->
			<div class="paralaxActivities">
				<div class="swiper-container swiper-parent">
					<div v-if="!isMobile" class="swiper-wrapper w-100" :style="'background-image: url(\''+uploader(heroImage.mhi_image)+'\'); background-size: cover; background-position: center top; width: 1349px;'">
						<div class="container clearfix">
							<div class="slider-caption go-mid dark">
								<h3>{{heroImage.mhi_title}}</h3>
              					<p class="mt-2">{{heroImage.mhi_desc}}</p>
							</div>
						</div>
						<div class="video-overlay" style="background-color: rgba(0,0,0,0.3);"></div>
					</div>
					<div v-else class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image_mobile)+'\'); background-size: cover; background-position: center top; width: 1349px;'">
						<div class="container clearfix">
							<div class="slider-caption go-mid dark">
								<h3>{{heroImage.mhi_title}}</h3>
              					<p class="mt-2">{{heroImage.mhi_desc}}</p>
							</div>
						</div>
						<div class="video-overlay" style="background-color: rgba(0,0,0,0.3);"></div>
					</div>
				</div>
				<a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
			</div>
		</section>
		<section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<h1 class="d-none d-md-block">&nbsp;</h1>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{ web.mn_contact }}</li>
				</ol>
			</div>
		</section>
		<section id="content" class="overflow-none">
			<div class="content-wrap">
				<section class="section nobg nobottommargin notopmargin">
					<div class="container">
						<div class="row">
							<div class="col-sm-6">
								<div class="heading-block">
									<h3>Hubungi <span>Farmsco</span> </h3>
								</div>
								<VForm @resp="submitForm" method="post">
									<div class="row mb-3">
										<div class="col-md-12">
											<label for="template-contactform-name">Name <small>*</small></label>
											<input type="text" title="Input Name" v-model="input.name" id="template-contactform-name" name="template-contactform-name" required="" value="" class="sm-form-control required valid">
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-lg-6">
											<label for="template-contactform-email">Email <small>*</small></label>
											<input type="email" title="Input Email" id="template-contactform-email" name="template-contactform-email" required="" v-model="input.email" class="required email sm-form-control">
										</div>
										<div class="col-lg-6 col_last mt-3 mt-lg-0">
											<label for="template-contactform-phone">Phone <small>*</small></label>
											<input type="tel" required="" title="Input Phone" id="template-contactform-phone" v-model="input.phone" name="template-contactform-phone" value="" class="sm-form-control">
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-md-12">
											<label for="template-contactform-subject">Subject <small>*</small></label>
											<input v-model="input.subject" title="Input Subject" type="text" id="template-contactform-subject" name="subject" value="" class="required sm-form-control">
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-md-12">
											<label for="template-contactform-message">Message <small>*</small></label>
											<textarea required="" title="Input Message" v-model="input.message" class="sm-form-control" rows="6" style="height: auto;"></textarea>
										</div>
									</div>
									<div class="info alert alert-success" v-if="info">{{infoText}}</div>
									<div class="col_half">
										<button class="btn btn-border_red mt-3 btn-loading" href="javascript:;">{{ web.btn_kirim_pesan }}</button>
									</div>
									<input type="hidden" name="prefix" value="template-contactform-">
								</VForm>
							</div>
							<div class="col-sm-6 m_top_60">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.705739761862!2d106.65020571431096!3d-6.302340763433063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb4a876abafd%3A0xc85bdc9d6f12b85f!2sBSD%20Green%20Office%20Park%209!5e0!3m2!1sen!2sid!4v1574646166970!5m2!1sen!2sid"
									width="600" height="450" frameborder="0" style="border:0;"
									allowfullscreen="" class="w-100"></iframe>
							</div>
						</div>
					</div>
				</section>

				<section class="section nobottommargin notopmargin">
					<div class="container" v-if="data.length">
						<div class="row">
							<div class="col-md-12">
								<div class="heading-block center">
									<h3>{{ web.lbl_contact_sales_manager }}</h3>
								</div>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-sm-6 col-md-6 col-lg-4" v-for="(v,k) in data">
								<div class="wrap_contact_pic">
									<div class="ic_contact">
										<i class="icon-phone3"></i>
									</div>
									<h3>{{ v.msm_area }}</h3>
									<p>{{ v.msm_name+' '+v.msm_phone }}</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
  	</div>
</template>